<template>
	<div class="lump">
		<img class="title" src="@/assets/images/newyear/active-title.png" alt="">
		<div class="content">
			<p class="item mb">1、活动日期<span class="time">2022-04-30</span>日至<span class="time">2022-05-15</span>日，且本活动仅在此时间内有效；</p>
			<p class="item">2、活动期间内富豪指数到指定数即可领取丰厚奖励。奖励自动发放</p>
			<div class="total-num">
				<div class="column">
					<p class="p-1">土豪指数</p>
					<p class="p-2">奖励1</p>
					<p class="p-3">奖励2</p>
					<p class="p-4">奖励3</p>
				</div>
				<div v-for="(item, index) in rewardList" :key="index" class="column blod">
					<p class="p-1 coffee">{{item.recharge}}</p>
					<p class="p-2">语音卡X{{item.voiceCard}}</p>
					<p class="p-3" :class="item.videoCard == 0? 'null':''">视频卡X{{item.videoCard}}</p>
					<p class="p-4" :class="item.jewel == 0? 'null':''">{{item.jewel}}赠送钻石</p>
				</div>
			</div>
			<p class="item mb">3、开心指数值累计活动期间内消耗的钻石数，如消费钻石的视频、语音、送礼等累计才计算开心指数。活动期间外消耗钻石和背包礼物、视频卡、语音卡、赠送钻石等不计入消耗累计。</p>
			<p class="item">4、活动结束后排行榜前十可获得礼物奖励</p>
			<div class="total-no">
				<div class="column">
					<p class="p-1">名次</p>
					<p class="p-2">奖励1</p>
					<p class="p-3">奖励2</p>
					<p class="p-4">奖励3</p>
				</div>
				<div v-for="(item, index) in noList" :key="index" class="column blod">
					<p class="p-1 coffee">{{item.no}}</p>
					<p class="p-2">{{item.gift}}</p>
					<p class="p-3" :class="item.videoCard == 0? 'null':''">视频卡X{{item.videoCard}}</p>
					<p class="p-4" :class="item.voiceCard == 0? 'null':''">语音卡X{{item.voiceCard}}</p>
				</div>
			</div>
			<p class="item">5、消费1钻石等于1开心指数。</p>
			<p class="item mr">6、开心指数相同时，先到达该指数则排在前面。</p>
			<p class="item">7、本次活动所有最终解释权归平台所有。</p>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				rewardList: [
					{ recharge: 499, voiceCard: 1, videoCard: 0, jewel: 0 },
					{ recharge: 1199, voiceCard: 2, videoCard: 1, jewel: 0 },
					{ recharge: 2199, voiceCard: 2, videoCard: 1, jewel: 100 },
					{ recharge: 4399, voiceCard: 2, videoCard: 2, jewel: 200 },
					{ recharge: 7999, voiceCard: 3, videoCard: 2, jewel: 300 },
					{ recharge: 11999, voiceCard: 3, videoCard: 3, jewel: 500 },
					{ recharge: 22999, voiceCard: 4, videoCard: 4, jewel: 888 },
					{ recharge: 33999, voiceCard: 4, videoCard: 6, jewel: 1999 },
					{ recharge: 69999, voiceCard: 7, videoCard: 8, jewel: 2999 },
					{ recharge: 999999, voiceCard: 15, videoCard: 15, jewel: 4888 }
				],
				noList: [
					{ no: '第一名', videoCard: 50, voiceCard: 50, gift: '裸钻X3' },
					{ no: '第二名', videoCard: 30, voiceCard: 30, gift: '520气球X3' },
					{ no: '第三名', videoCard: 10, voiceCard: 10, gift: '520气球X1' },
					{ no: '第四名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第五名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第六名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第七名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第八名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第九名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' },
					{ no: '第十名', videoCard: 0, voiceCard: 0, gift: '爱心玫瑰花束X1' }
				]
			}
		}
	}
</script>

<style lang="stylus" scoped>
	.lump
		padding 0.48rem 0.346666rem 1.066666rem
		box-sizing border-box
		background linear-gradient(0deg, #DD4743, #E98955)
		.title
			display block
			margin 0 auto 0.42rem
			width 6.533333rem
			height 1.333333rem
		.content
			padding 0.7rem 0.54rem 0.46rem
			background  #FFF0D4
			border-radius: 12px;
			text-align left
			box-shadow: 0px 6px 32px 0px #E6603C, 0px 0px 90px 0px #D53A36;
			.item
				font-size 0.35rem
				color #DC4539
				font-weight 500
				line-height 0.6rem
				.time
					margin 0 2px
					letter-spacing 1px
			.mr
				margin 0.12rem 0
			.mb 
				margin-bottom 0.4rem
			.total-num
				margin 0.36rem 0 0.6rem
				.column
					display flex
					justify-content center
					align-items center
					border-top 1px solid #FFB981
					height 0.8rem
					text-align center
					font-size 0.293333rem
					color #333333
					font-weight 500
					p 
						height 0.8rem
						line-height 0.9rem
						box-sizing border-box
						border-left 1px solid #FFB981
					.p-1 
						width 20%
					.p-2
						width 24%
					.p-3
						width 24%
					.p-4
						width 32%
						border-right 1px solid #FFB981
					.coffee
						color #845400
					.null
						color #FFF0D4
				.column:nth-last-child(1)
					border-bottom 1px solid #FFB981
				.blod
					font-weight bold
					font-size 0.32rem
			.total-no
				margin 0.3rem 0 0.6rem
				.column
					display flex
					justify-content center
					align-items center
					border-top 1px solid #FFB981
					height 0.8rem
					text-align center
					p 
						height 0.8rem
						line-height 0.9rem
						box-sizing border-box
						border-left 1px solid #FFB981
					.p-1
						width 20%
					.p-2
						width 34%
					.p-3
						width 23%
					.p-4
						width 23%
						border-right 1px solid #FFB981
					.coffee
						color #845400
					.null
						color #FFF0D4
				.column:nth-last-child(1)
					border-bottom 1px solid #FFB981
				.blod
					font-weight bold
</style>
